@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap');

body {
	margin: 0;
	background-color: #0f0f0f;
	color: #f4f4f4;
	font-family: 'Barlow';
	font-size: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
	background: transparent !important;
	opacity: 1;
}

::-webkit-scrollbar {
	background: transparent !important;
}
